import React, { useEffect, useState } from "react";
import "./gallery.css";
import LandingHeader from "../Landing/LandingHeader";
import { Col, Row, Segmented, Spin, Image, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import LazyLoad from "react-lazyload";

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allImages, setAllImages] = useState([]);
  const [loadedImages, setLoadedImages] = useState(new Set());
  const [imageCount, setImageCount] = useState(16);
  const [mandals, setMandals] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Praja Darbar");
  const [selectedMandal, setSelectedMandal] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState(null);

  // Mocked mandal and village data
  const items1 = [
    {
      mandal: "Kuppam",
      villages: ["Kangundhi"],
      dates: ["13.11.2024"],
    },
    {
      mandal: "Rama kuppam",
      villages: ["Bandarlapalle","Abakaladoddi"],
      dates: ["21.11.2024","04.12.2024"],
    },
    {
      mandal: "Gudi Palle",
      villages: ["Sodiganipalli"],
      dates: ["29.11.2024"],
    }
  ];

  useEffect(() => {
    // Set default mandal and village
    if (items1.length > 0) {
      setSelectedMandal(items1[0].mandal);
      setSelectedVillage(items1[0].villages[0]);
    }
  }, []);

  // useEffect(() => {
  //   const fetchMandals = async () => {
  //     try {
  //       const mandalsData = await getMandals();
  //       console.log("mandalsData:", mandalsData?.data);
  //       setMandals(mandalsData?.data || []);
  //     } catch (error) {
  //       console.error("Error fetching mandals:", error);
  //     }
  //   };
  //   fetchMandals();
  // }, []);

  const contexts = {
    "Praja Darbar": () => {
      if (selectedVillage === "Bandarlapalle") {
        return require.context(
          "../../assets/landingGallery/Praja Darbar/Rama kuppam/Bandarlapalle",
          false,
          /\.(png|jpe?g|svg|JPG)$/i
        );
      } else if (selectedVillage === "Kangundhi") {
        return require.context(
          "../../assets/landingGallery/Praja Darbar/kuppam/Kangundhi",
          false,
          /\.(png|jpe?g|svg|JPG)$/i
        );
      } else if (selectedVillage === "Sodiganipalli") {
        return require.context(
          "../../assets/landingGallery/Praja Darbar/Gudi Palle/Sodiganipalli",
          false,
          /\.(png|jpe?g|svg|JPG)$/i
        );
      }else if (selectedVillage === "Abakaladoddi") {
        return require.context(
          "../../assets/landingGallery/Praja Darbar/Rama kuppam/Abakaladoddi",
          false,
          /\.(png|jpe?g|svg|JPG)$/i
        );
      }
    },
  };
  useEffect(() => {
    const loadImages = async () => {
      setLoading(true); // Start loading
      try {
        const contextFunc = contexts[selectedOption];
        if (!contextFunc) {
          console.error(`No context found for the selected option: ${selectedOption}`);
          setImages([]); // No context found, reset images to empty array
        } else {
          const context = contextFunc();
          const importAll = (r) => r.keys().map(r);
          let imageFiles = importAll(context);
          console.log("imageFiles:", imageFiles);
          setAllImages(imageFiles); // Set all images
          setImages(imageFiles.slice(0, imageCount)); // Set initial batch of images
        }
      } catch (error) {
        console.error("Error loading images:", error);
        setImages([]); // In case of error, reset images to empty array
      } finally {
       if (images.length === 0) {
        setTimeout(() => {
          setLoading(false); 
        }, 1000); 
       }else{
        setLoading(false);
       }
       
      }
    };
  
    loadImages();
  }, [selectedOption, selectedMandal, selectedVillage, imageCount]);
  
  

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => new Set(prevLoadedImages.add(index)));
  };

  const loadMoreImages = () => {
    setImageCount((prevCount) => prevCount + 20);
  };

  const handleMandalChange = (mandal) => {
    setSelectedMandal(mandal);
    const selected = items1.find((item) => item.mandal === mandal);
    if (selected) {
      setSelectedVillage(selected.villages[0]); 
    }
  };

  return (
    <div className="gallery-page">
      <div className="gallery-header">
        <LandingHeader />
      </div>
      <div className="gallery-image-section">
        <div className="gallery-segmented">
          <Segmented
            style={{ padding: "5px 10px", borderRadius: "5px" }}
            options={["Praja Darbar"]}
            value={selectedOption}
            onChange={(value) => setSelectedOption(value)}
          />
        </div>
        <div className="gallery-image-container">
          <div style={{ marginBottom: "40px" }}>
            <h3 className="gallery-image-title">
              MANA PANCHAYAT VADDAKU{" "}
              <span style={{ color: "#fc6e01" }}>
                MANA CHANDRANNA PRABHUTHVAM
              </span>
            </h3>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                marginBottom: "10px",
              }}
            >
              <p>
                Praja Darbar Programme conducted by KADA Project Director Shri
                Vikas Marmat, IAS at {selectedVillage} Village, {selectedMandal}{" "}
                Mandal on{" "}
                <b>
                  {(() => {
                    const mandalData = items1.find(
                      (item) => item.mandal === selectedMandal
                    );
                    if (mandalData) {
                      const date =
                        mandalData.villages.indexOf(selectedVillage) !== -1
                          ? mandalData.dates[
                              mandalData.villages.indexOf(selectedVillage)
                            ]
                          : "";
                      return date || "Date not available";
                    }
                    return "Date not available";
                  })()}
                </b>
              </p>
            </div>

            <div style={{ width: "100%", display: "flex", gap: "30px" }}>
              {/* Mandal Dropdown */}
              <div
                style={{ marginBottom: "16px", display: "flex", gap: "5px" }}
              >
                <label
                  style={{
                    fontWeight: 500,
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Mandal:
                </label>
                <Dropdown
                  overlayStyle={{ minWidth: "150px" }}
                  overlay={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "150px",
                        background: "#ffff",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {items1.map((item) => (
                        <Button
                          key={item.mandal}
                          type="text"
                          onClick={() => handleMandalChange(item.mandal)}
                          style={{ textAlign: "left", padding: "8px 16px" }}
                        >
                          {item.mandal}
                        </Button>
                      ))}
                    </div>
                  }
                >
                  <Button style={{ width: "150px" }}>
                    {selectedMandal} <DownOutlined />
                  </Button>
                </Dropdown>
              </div>

              <div style={{ display: "flex", gap: "5px" }}>
                <label
                  style={{
                    fontWeight: 500,
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Village:
                </label>
                <Dropdown
                  overlayStyle={{ minWidth: "150px" }}
                  overlay={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        background: "#ffff",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {items1
                        .find((item) => item.mandal === selectedMandal)
                        ?.villages.map((village) => (
                          <Button
                            key={village}
                            type="text"
                            onClick={() => setSelectedVillage(village)}
                            style={{ textAlign: "left", padding: "8px 16px" }}
                          >
                            {village}
                          </Button>
                        ))}
                    </div>
                  }
                >
                  <Button style={{ width: "150px" }}>
                    {selectedVillage} <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" tip="Loading..." />
            </div>
          ) : images.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <p>No images found</p>
            </div>
          ) : (
            <Row gutter={[30, 20]}>
              {images.map((image, index) => (
                <Col xs={24} sm={12} md={8} lg={6} key={index}>
                  <LazyLoad height={200} offset={100} once>
                    <Image
                      alt={`Gallery item ${index}`}
                      src={image}
                      width={"100%"}
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "5px 5px 0 0",
                      }}
                      onLoad={() => handleImageLoad(index)}
                      placeholder={
                        <div
                          style={{
                            height: "200px",
                            backgroundColor: "#f0f0f0",
                          }}
                        />
                      }
                    />
                  </LazyLoad>
                </Col>
              ))}
            </Row>
          )}
          {!loading &&(
            <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              type="primary"
              onClick={loadMoreImages}
              loading={loading}
              disabled={images.length === allImages.length}
            >
              Load More
            </Button>
          </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default Gallery;
