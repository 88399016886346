import React from "react";
import { Layout } from "antd";

import "./Layout.css";
import PublicHeader from "../components/Toolbar/PublicHeader";

const { Header, Content, Footer } = Layout;

export default function PublicLayout({ children }) {
  return (
    <Layout className="layout" >
      <Header className="layout-header">
        <PublicHeader />
      </Header>
      <Content  className="layout-public-body">{children}</Content>
      <Footer className="layout-public-footer">
      Copyright © {new Date().getFullYear()} KADA. All rights reserved. Developed by <a href="https://beyondscale.tech/" target="_blank" style={{color:"#fc6e01",fontWeight:600}}>BeyondScale.</a> 
      </Footer>
    </Layout>
  );
}
