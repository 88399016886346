import React from "react";
import quotes from "../../assets/img/image.svg";
import img1 from "../../assets/img/group-50.png";
import img2 from "../../assets/img/group-51.png";
// import vidSrc from "https://pat.vsws.co.in/videos/kada-video.mp4";
import "./NewVision.css";
import { Card, Col, Row } from "antd";
import { OverPack } from "rc-scroll-anim";
import TweenOne from "rc-tween-one";
import QueueAnim from "rc-queue-anim";
import posterThumbnail from "../../assets/newLanding/thumbnail_ChandrababuNaidu.avif";
import vision1 from  "../../assets/newLanding/vision_1.jpg";
import vision2  from "../../assets/newLanding/vision_2.webp"
import vision3  from "../../assets/newLanding/vision_3.jpg"

const NewVision = () => {
  const { Meta } = Card;

  return (
    <div className="vision-container" id="vision">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <div className="group-9">
            <p className="vision-header">
              <span
                style={{
                  // fontSize: "2.5rem",
                  marginRight: "15px",
                  // fontFamily: "'Open Sans', sans-serif",
                }}
              >
                Vision{" "}
              </span>{" "}
              <span className="orange-title">2029</span>
            </p>
            <p className="vision-description">
              At KADA, our vision is to nurture a thriving community where every
              citizen has access to quality services, economic opportunities,
              and a high standard of living. Through strategic initiatives and
              collaborative efforts, we aim to promote equitable growth, drive
              progress, and establish Kuppam as a beacon of sustainable
              development in the region.
            </p>
            {/* <div className="group-10"> */}
            <div className="overlap-group-6">
              <img className="quote-image" alt="Quote" src={quotes} />
              <p className="vision-goal">
                KADA will be a Zero Poverty region by 2029
              </p>
              <div className="vision-cm-name">Shri. N. Chandrababu Naidu</div>
              <p className="vision-cm-title">
                Hon'ble Chief Minister of Andhra Pradesh
              </p>
            </div>

            {/* </div> */}
          </div>
        </Col>
        <Col xs={24} lg={12} style={{ display: "flex", alignItems: "center" }}>
          <Card
            cover={
              <video
                src={"https://pat.vsws.co.in/videos/kada-video.mp4"}
                controls
                className="story-video"
                poster={posterThumbnail}
              />
            }
          >
            <Meta
              style={{ fontFamily: "sans-serif" }}
              title="Making Kuppam a Zero Poverty Region"
              description="AP Hon'ble CM Chandrababu Naidu shares his vision on reviving KADA during a public meeting at Kuppam."
            />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]} className="images-row">
        <Col key="a" lg={8} xs={24}>
          <Card
            hoverable
            bordered={false} 
            cover={<video src="" controls className="story-video" poster="" />}
            styles={{ body: { paddingLeft: "15px", fontWeight: "100" } }}
            style={{ padding: "0px" }} 
          ></Card>
        </Col>
        <Col key="b" lg={8} xs={24}>
          <Card
            hoverable
            bordered={false}
            cover={<video src="" controls className="story-video" poster="" />}
            style={{ padding: "0" }} 
            styles={{ body: { paddingLeft: "15px" } }}
          ></Card>
        </Col>
        <Col key="c" lg={8} xs={24}>
          <Card
            hoverable
            styles={{ body: { paddingLeft: "15px" } }}
            bordered={false}
            cover={<video src="" controls className="story-video" poster="" />}
            style={{ padding: "0" }} 
          ></Card>
        </Col>
      </Row> */}

      <Row
        gutter={[16, 16]}
        className="images-row"
        style={{ minHeight: "320px" }}
      >
        <OverPack style={{ overflow: "hidden", height: "auto" }}>
          <TweenOne
            key="0"
            animation={{ opacity: 1, duration: 1000 }}
            className="code-box-shape"
            style={{ opacity: 0, marginBottom: 10 }}
          />
          <QueueAnim
            key="queue"
            leaveReverse
            duration={500}
            ease={["easeInOutQuart"]}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col key="a" lg={8} xs={24}>
              <img
                className="vision-img"
                src={vision1}
                alt="image 1"
              />
            </Col>
            <Col key="b" lg={8} xs={24}>
              <img
                className="vision-img"
                src={vision2}
                alt="image 2"
              />
            </Col>
            <Col key="c" lg={8} xs={24}>
              <img
                className="vision-img"
                src={vision3}
                alt="image 3"
              />
            </Col>
          </QueueAnim>
        </OverPack>
      </Row>
    </div>
  );
};

export default NewVision;
