import React from "react";
import { Layout } from "antd";

import "./Layout.css";
import Toolbar from "../components/Toolbar/Toolbar";

const { Header, Content, Footer } = Layout;

function DashboardLayout({ children }) {
  return (
    <Layout>
      <Header className="layout-header">
        <Toolbar />
      </Header>
      <Content className="layout-body">{children}</Content>
      <Footer className="layout-footer">
      Copyright © {new Date().getFullYear()} KADA. All rights reserved. Developed by <a href="https://beyondscale.tech/" target="_blank" style={{color:"#fc6e01",fontWeight:600}}>BeyondScale.</a> 
      </Footer>
    </Layout>
  );
}

export default DashboardLayout;
  